
import { defineComponent } from 'vue'
import authStore from '@/store/auth.store'
export default defineComponent({
    name: 'WatAcademy',
    data(){
        return{
            showAdvert: true,
            elements:[
                {
                    title:'Encuestas Online: La Vanguardia de la Opinión Digital',
                    text:'En la WATAcademy, aprenderás que las encuestas en línea son mucho más que simples cuestionarios digitales. Son herramientas poderosas para conectar a personas de todo el mundo y dar forma a productos, servicios y decisiones empresariales. Desde tu dispositivo, tú tienes el poder de influir y contribuir a la toma de decisiones.',
                    type:'survey'
                },
                {
                    title: 'Comunidades Online: Cunas de Creatividad y Soluciones Reales',
                    text:'Las comunidades en línea son espacios donde tus ideas se convierten en soluciones reales. Aquí, participarás en debates, compartirás tus pasiones y serás parte del cambio. En la WATAcademy, te enseñaremos a sacar el máximo provecho de estas comunidades y a ser un líder en la creación de soluciones innovadoras.',
                    type:'community'
                },
                {
                    title:'Focus Groups Online: Tormentas de Ideas Globales',
                    text:'Los Focus Groups Online no son reuniones virtuales comunes. Son tormentas de ideas globales, guiadas por un moderador, en las que líderes de opinión como tú se reúnen para discutir, debatir y transformar ideas en innovación. Aquí, la distancia no es un obstáculo para la creatividad, y aprenderás a ser un líder en estas sesiones de brainstorming globales.',
                    type:'focus'
                },
                {
                    title:'Entrevistas 1:1 Online: Viajes Virtuales de Descubrimiento',
                    text:'Las entrevistas 1:1 en línea son más que conversaciones ordinarias. Son viajes virtuales de descubrimiento en los que explorarás pensamientos y emociones profundos. Desde la comodidad de tu pantalla, contribuirás a un mundo más informado y aprenderás a influir bajo la guía de un moderador experto.',
                    type:'interview'
                },
                {
                    title:'Pruebas de Usabilidad o UX: La Revolución de la Experiencia Digital',
                    text:'Dentro de las pruebas de usabilidad en línea, descubrirás una serie de métodos, como el “Click Test” para evaluar enlaces, el “Card sorting” para organizar información intuitivamente, la “Navegación con Thinking Aloud” para compartir pensamientos en tiempo real y la “Navegación sin Thinking Aloud” para evaluar la experiencia personal.',
                    type: 'ux'
                }
            ]
        }
    },
    methods:{
        login(){
            authStore.loginWatAcademy().then((data) => {
                window.open("https://watlab.es/c/watitdemos/login?testerAuthToken="+data.authToken, "_blank");
            })
            .catch((error) => {
                alert("Error")
                console.log(error)
            });
        },
        closeAdvert(){
            this.showAdvert = false
        }
    }
})
